import {Component, OnInit, Input, ElementRef, Output, EventEmitter, ViewChild, OnDestroy} from '@angular/core';
import {empAccTableHeader, empOrAccTableParticipantHeader} from '../../models/manage-employee-accounts-table-header';
import {SharedService} from '../../shared.service';
import {DatePipe} from '@angular/common';
import {SharedConstant} from '../../constants/sharedConstants';
import {
  empOrParticipantSecurityTableHeader
} from '../../models/magage-employee-group-accounts-table-header';
import {MessageService} from 'primeng/api'
import {Paginator} from 'primeng/paginator';
import {ManageEmpGrpService} from '../../services/manage-emp-grp-service/manage-emp-grp.service';
import {ManageEmployeeDataService} from '../../services/manage-employee/manage-employee-data.service';
import {Subscription} from 'rxjs';
import {CustomSortService} from '../../services/custom-sort/custom-sort.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AccountNumberValidationService} from '../../services/accountNumberValidation/account-number-validation.service';

@Component({
  selector: 'ems-shared-manage-employee-accounts',
  templateUrl: './shared-manage-employee-accounts.component.html'
})
export class SharedManageEmployeeAccountsComponent implements OnInit, OnDestroy {

  @Input() getOrgId: any;
  @Input() showColumns: any = true;
  @Input() assignFormData: any;
  @Input() editGroupData: any;
  @Input() empAccformData: any;
  @Input() moduleType: any;
  @Output() selectedEmployeeAccounts: any = new EventEmitter();
  @Output() newlySelectedEmployeeAccounts: any = new EventEmitter();
  @Output() rowSelected: any = new EventEmitter();
  @Output() selectedAccounts: any = new EventEmitter();
  @Output() modifiedSubAccounts: any = new EventEmitter();
  @Output() employeeAccounts: any = new EventEmitter();
  @ViewChild('p', { static: true }) paginator: Paginator;
  employees: any[];
  totalRecords: any;
  noOfRecords: any = 15;
  selectedArr: any = [];
  pageVal: any = 1;
  sortData: any;
  empIds: any[] = [];
  getSharedConstant = SharedConstant;
  empAccntTblHeader: any = empOrAccTableParticipantHeader;
  manageEmpGrpAccTblHeader: any = empOrParticipantSecurityTableHeader;
  currentParentNode: any;
  empAccTableCols: any[];
  subAccntNumbers: any[] = [];
  chkCompanyGrp: any;
  validateCompanyGrp: any;
  child = 0;
  parent = 0;

  param = {
    orgId: '',
    pageNumber: 1,
    pageSize: 1,
    sortField: ''
  };

  totalPagesCount: any;
  pageNoCount: any;
  getCompanyGrpEmpCount: any = 0;
  getCompanyGrpAccCount: any = 0;
  sortField: any;
  pageNo: any = 1;
  toggle = false;
  errMsg = [];
  filterClicked = false;
  filterBtnDisableForFN = false;
  filterBtnDisableForLN = false;
  filterBtnDisableForEmpId = false;
  filterBtnDisableForacctHolderFN = false;
  filterBtnDisableForacctHolderLN = false;
  invalidEmpId = false;
  invalidFN = false;
  invalidLN = false;
  invalidacctHolderFN = false;
  invalidacctHolderLN = false;
  empAccountForm: UntypedFormGroup;
  isAccNumInvalid = false;
  selectedNewRecords = [];
  alreadySelectedRemoval: any = [];
  employeeSelection = '';
  removedExistingEmpId: any = [];
  subAccntNewlySelected: any = [];
  unModifiedRecords: any = [];
  selectedNoOfRecords = false;
  modifiedSubAccnts: any = [];
  businessUnit: any;
  multiSortMeta: any = [];
  currentOrgSubscription: Subscription;
  isSortingChanged = false;
  defaultSortDataField = {field: 'updTs', order: -1};
  initialLoad = false;
  serviceOrgId: any;

  constructor(public fb: UntypedFormBuilder, public customSortService: CustomSortService, public sharedService: SharedService, public datePipe: DatePipe, private el: ElementRef,
    public manageEmpGrpService: ManageEmpGrpService, public manageEmployeeDataService: ManageEmployeeDataService, private accountNumberValidationService: AccountNumberValidationService,
    public messageService: MessageService) {
  }

  paramObj(pageNo, records, sortCol) {
    return this.param = {
      orgId: this.serviceOrgId,
      pageNumber: pageNo,
      pageSize: records,
      sortField: sortCol
    };
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.sharedService.isAccountNumInvalid = false;
  }

  ngOnInit() {
    this.currentOrgSubscription = this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo && clientInfo !== null) {
        this.serviceOrgId = clientInfo.orgId;
        this.businessUnit = clientInfo.businessUnit;
        this.empAccTableCols = this.businessUnit ? this.showColumns === false ? this.manageEmpGrpAccTblHeader[this.businessUnit] : this.empAccntTblHeader[this.businessUnit] : '';
      }
    });
    this.initialLoad = true;
    this.createEmpAccountform();
    this.empIds = [];
    this.subAccntNumbers = [];
    this.multiSortMeta.push(this.defaultSortDataField);
    this.customSortService.sortArr = [];
    this.onChange();
    this.resetFilterForm();
  }

  createEmpAccountform() {
    this.empAccountForm = this.fb.group({
      empId: [''],
      acctNum: [''],
      firstName: [''],
      lastName: [''],
      acctHolderFn: [''],
      acctHolderLn: [''],
    });
  }

  resetFilterForm() {
    this.sharedService.resetFilterForm.subscribe(val => {
      /* if user changes the tab then it will reset the filter form for previous active tab but won't make any API call for that */
      if (val.tabChange && this.empAccountForm.dirty) {
        this.empAccountForm.reset();
        this.empAccountForm.markAsPristine();
        this.sharedService.clearValidationFields();
        this.filterBtnDisableForFN = false;
        this.filterBtnDisableForLN = false;
        this.filterBtnDisableForEmpId = false;
        this.filterBtnDisableForacctHolderFN = false;
        this.filterBtnDisableForacctHolderLN = false;
        this.invalidEmpId = false;
        this.invalidFN = false;
        this.invalidLN = false;
        this.invalidacctHolderFN = false;
        this.invalidacctHolderLN = false;
      }
      /* if user clicks to previous active tab then only it will make API call. API call will happen when toggle has been clicked. */
      if (val.tabName === this.sharedService.getSharedConstant[this.businessUnit].employeeOrParticipant && (this.toggle || this.filterClicked)) {        
        this.filterClicked = false;
        this.toggle = false;
        const noOfRecords = this.param.pageSize;
        this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
      }
    });
  }

  getEmpAccntResponse(param, initialLoad) {
    const localUpdatedFormValues = this.empAccountForm.getRawValue();

    const filterObj: any = {
      orgId: this.serviceOrgId,
      empId: localUpdatedFormValues.empId ? localUpdatedFormValues.empId : null,
      accountNum: localUpdatedFormValues.acctNum ? localUpdatedFormValues.acctNum : null,
      firstName: localUpdatedFormValues.firstName ? localUpdatedFormValues.firstName : null,
      lastName: localUpdatedFormValues.lastName ? localUpdatedFormValues.lastName : null,
      accountHolderFN: localUpdatedFormValues.acctHolderFn ? localUpdatedFormValues.acctHolderFn : null,
      accountHolderLN: localUpdatedFormValues.acctHolderLn ? localUpdatedFormValues.acctHolderLn : null
    };

    const defaultObj: any = {
      orgId: this.serviceOrgId       
    };

    if (this.filterClicked) {
      this.sharedService.getEmployeeList(param, filterObj, null, null).subscribe((response) => {
        this.setEmployeeAccntListData(response, initialLoad, filterObj);
      });
    } else {
      this.sharedService.getEmployeeList(param, defaultObj, null, null).subscribe((response) => {
        this.setEmployeeAccntListData(response, initialLoad, defaultObj);
      });
    }
  }

  setEmployeeAccntListData(response, initialLoad, dataObj) {
    this.employees = response.data.content;
    if (this.editGroupData && this.editGroupData.oldRuleIdForCopy !== '' && this.editGroupData.mode === 'Copy') {
      this.editGroupData.id = this.editGroupData.oldRuleIdForCopy;
    }
    if ((this.moduleType === 'EmployeeGroup' && this.editGroupData.mode === 'EDIT') || this.moduleType === 'Rules') {
      this.empAccformData = this.formSelectedArr(JSON.parse(JSON.stringify(response.data.content)), dataObj, initialLoad);
    }
    if (response.data) {
      this.populateData(this.employees);
      this.totalRecords = response.data.totalElements;
      this.unModifiedRecords = JSON.parse(JSON.stringify(this.employees));

      if (this.empAccformData && initialLoad && (this.assignFormData.employeeAccounts.length > 0 || (this.moduleType === 'Rules' && this.assignFormData.status === 'DRAFT'))) {
        this.subAccntNumbers = this.assignFormData.subAccntNumbers;
        this.empIds = this.assignFormData.empIds;
        if ((this.moduleType === 'EmployeeGroup' && this.editGroupData.mode === 'EDIT') || this.moduleType === 'Rules') {
          this.assignFormData.empIds = [];
          this.assignFormData.subAccntNumbers = [];
        }
        /* eslint-disable-next-line max-len */
        this.selectedArr = (this.assignFormData.empIds && !this.assignFormData.empIds.length && this.assignFormData.subAccntNumbers &&
          !this.assignFormData.subAccntNumbers.length) ?
          this.populateSelectedArr(this.formatJson(this.empAccformData, ''), this.employees, dataObj) : this.empAccformData;
        /* Removing parent empId selection if only one children is checked in both Create/Edit Scenario */
        this.removeEmployeeParent();
      }
      const editGroupData = (this.moduleType === 'EmployeeGroup') ? this.editGroupData.employeeGroupAssignInfo : this.editGroupData;
      /* eslint-disable-next-line max-len */
      if ((this.selectedNewRecords.length > 0 || this.alreadySelectedRemoval.length > 0 || this.subAccntNewlySelected.length > 0 || this.removedExistingEmpId.length > 0 ||
        (editGroupData && editGroupData.newSelectedRecords && editGroupData.newSelectedRecords.length > 0) ||
        (editGroupData && editGroupData.newSelectedRecords && editGroupData.subAccntNewlySelected.length > 0) ||
        (editGroupData && editGroupData.newSelectedRecords && editGroupData.removedExistingEmpId.length > 0) ||
        (editGroupData && editGroupData.removedExistingSelected && editGroupData.removedExistingSelected.length > 0)) && (this.moduleType === 'EmployeeGroup' && this.editGroupData.mode === 'EDIT') || this.moduleType === 'Rules') {

        const formSelectedRecords = (editGroupData.newSelectedRecords && editGroupData.newSelectedRecords.length > 0) ? [...editGroupData.newSelectedRecords] : [];
        const formRemovedRecords = (editGroupData.removedExistingSelected && editGroupData.removedExistingSelected.length > 0) ? [...editGroupData.removedExistingSelected] : [];

        const formMultipleSubAccntSelected = (editGroupData.subAccntNewlySelected && editGroupData.subAccntNewlySelected.length > 0) ? [...editGroupData.subAccntNewlySelected] : [];
        const formRemovedMultipleSubAccnts = (editGroupData.removedExistingEmpId && editGroupData.removedExistingEmpId.length > 0) ? [...editGroupData.removedExistingEmpId] : [];

        const localSelectedArr = (this.selectedNewRecords.length > 0) ? [...this.selectedNewRecords] : [...formSelectedRecords];
        const localRemovedArr =  (this.alreadySelectedRemoval.length > 0) ? [...this.alreadySelectedRemoval] : [...formRemovedRecords];

        const localRemovedEmpId = (this.removedExistingEmpId.length > 0) ? [...this.removedExistingEmpId] : [...formRemovedMultipleSubAccnts];

        this.selectedNewRecords = (this.selectedNewRecords && this.selectedNewRecords.length > 0) ? this.selectedNewRecords : [...formSelectedRecords];
        this.alreadySelectedRemoval = (this.alreadySelectedRemoval && this.alreadySelectedRemoval.length > 0) ? this.alreadySelectedRemoval : [...formRemovedRecords];

        this.subAccntNewlySelected = (this.subAccntNewlySelected && this.subAccntNewlySelected.length > 0) ? this.subAccntNewlySelected : [...formMultipleSubAccntSelected];
        this.removedExistingEmpId = (this.removedExistingEmpId && this.removedExistingEmpId.length > 0) ? this.removedExistingEmpId : [...formRemovedMultipleSubAccnts];

        this.checkDataExists(localRemovedArr, localRemovedEmpId);

        this.emitNewlySelectedRecords();
        if (localSelectedArr.length > 0 || localRemovedArr.length > 0) {
          this.removeOrAddRecords(localSelectedArr, localRemovedArr);
        }
      }
    }
    this.selectedArr = [...this.selectedArr];
    this.selectedEmployeeAccounts.emit(this.selectedArr);
    if (this.editGroupData && this.editGroupData.oldRuleIdForCopy !== '' && this.editGroupData.mode === 'Copy') {
      this.selectedNewRecords.push(...this.selectedArr);
      this.emitNewlySelectedRecords();
    }
    if (this.selectedNoOfRecords) {
      this.setEmpCount();
      this.selectedNoOfRecords = false;
    } else {
      this.setEmpCount();
    }
  }

  checkDataExists(localRemovedArr, localRemovedEmpId) {
    if (localRemovedEmpId.length > 0) {
      localRemovedEmpId.forEach((removed) => {
        const index = localRemovedArr.findIndex(x => x.data.empId === removed.data.empId);
        if (index === -1) {
          localRemovedArr.push(removed);
        }
      });
    }
  }

  setEmpCount() {
    this.getSelectedAccCount(this.selectedArr);
  }

  removeEmployeeParent() {
    this.employees.forEach((emp) => {
      if (emp.children && !(emp.children.every(val => (this.selectedArr.findIndex(t => (t.data.empId === val.data.empId)) >= 0)))) {
        this.selectedArr.forEach((selected, index) => {
          if (emp.data.empId === selected.data.empId && emp.children.length !== selected.children.length) {
            this.selectedArr.splice(index, 1);
          }
        });
      }
    });
    if (this.subAccntNewlySelected.length > 0) {
      this.subAccntNewlySelected.forEach((selected) => {
        const index = this.selectedArr.findIndex(x => x.data.empId === selected.data.empId);
        if (index === -1) {
          this.selectedArr.push(selected);
        }
      });
    }
  }

  insertRecordInSelectedArr(localSelectedArr) {
    localSelectedArr.forEach((emp) => {
      this.insertRecordInArr(emp);
      if (emp.children && emp.children.length > 0 ) {
        emp.children.forEach((child) => {
          this.insertRecordInArr(child);
        });
      }
    });
  }

  insertRecordInArr(emp) {
    const index = this.selectedArr.findIndex(x => x.data.empId === emp.data.empId);
    if (index === -1) {
      this.selectedArr.push(emp);
    }
  }

  removeRecordFromArr(emp) {
    const index = this.selectedArr.findIndex(x => x.data.empId === emp.data.empId);
    if (index > -1) {
      this.selectedArr.splice(index, 1);
    }
  }

  removeSelectedFromRemoved(localSelectedArr, localRemovedArr) {
    if (localSelectedArr && localSelectedArr.length > 0) {
      localSelectedArr.forEach((emp) => {
        if (emp.children && emp.children.length > 0) {
          emp.children.forEach((child) => {
            if (child.selectedEmployee) {
              const indexRemoved = localRemovedArr.findIndex(x => x.data.empId === child.data.empId);
              if (indexRemoved > -1) {
                localRemovedArr.splice(indexRemoved, 1);
              }
            }
          });
        } else if (emp.parent) {
          const indexRemoving = localRemovedArr.findIndex(x => x.data.empId === emp.data.empId);
          if (indexRemoving > -1) {
            localRemovedArr.splice(indexRemoving, 1);
          }
        }
      });
    }
  }

  removeOrAddRecords(localSelectedArr, localRemovedArr) {
    this.insertRecordInSelectedArr(localSelectedArr);
    this.removeSelectedFromRemoved(localSelectedArr, localRemovedArr);
    const addedParentRecord = [...localRemovedArr];
    localRemovedArr.forEach((emp) => {
      if (emp.parent && emp.parent.children.length > 0) {
        addedParentRecord.push(emp.parent);
      }
    });

    addedParentRecord.forEach((emp) => {
      if (emp.children && emp.children.length > 0 && (emp.children.every(val => (this.selectedArr.findIndex(t => (t.data.empId === val.data.empId)) >= 0)))) {
        emp.children.forEach((children) => {
          this.removeRecordFromArr(children);
        });
      }
      this.removeRecordFromArr(emp);
    });
    this.removeEmployeeParent();
  }

  populateSelectedArr(selectedArr, totalEles, dataObj) {
    let selectedEmpAccounts = [];
    const data = this.sharedService.populateEmpAccounts(selectedArr, selectedArr, selectedEmpAccounts, this.empIds, this.subAccntNumbers);
    selectedEmpAccounts = data.selectedArr;
    this.empIds = data.empIds;
    this.subAccntNumbers = data.subAccntNumbers;
    const emitData = {
      empIds: this.empIds,
      subAccntNumbers: this.subAccntNumbers,
      selectedArr: selectedEmpAccounts
    };
    this.assignFormData.employeeAccounts = selectedEmpAccounts;
    if ((this.moduleType === 'EmployeeGroup' && this.editGroupData.mode === 'EDIT') || this.moduleType === 'Rules') {
      if (this.selectedArr.length === 0) {
        this.selectedArr = selectedEmpAccounts;
      } else {
        const prevSelectedArr = this.selectedArr;
        this.selectedArr = selectedEmpAccounts;
        this.insertRecordInSelectedArr(prevSelectedArr);
      }
    } else {
      this.selectedArr = selectedEmpAccounts;
      this.getSelectedAccCount(this.selectedArr);
    }

    this.employeeAccounts.emit(emitData);
    this.param.pageSize = this.noOfRecords;
    return this.selectedArr;
  }

  formSelectedArr(employee, dataObj, initialLoad) {
    const selectedArr = [];
    let updatedPaginatedArr = [];
    employee.forEach((empRecord) => {
      const subAccntArr = [];
      empRecord.accounts.find(item => {
        if ((this.moduleType === 'EmployeeGroup' && item.groupIds && item.groupIds.indexOf(this.editGroupData.id) !== -1) || (this.moduleType === 'Rules' && item.ruleIds && item.ruleIds.indexOf(this.editGroupData.id) !== -1)) {
          subAccntArr.push(item);
        }
      });
      if (empRecord.accounts.length === subAccntArr.length) {
        selectedArr.push(empRecord);
      } else if (subAccntArr.length !== 0) {
        empRecord.accounts = subAccntArr;
        selectedArr.push(empRecord);
      }
    });
    if (!initialLoad) {
      updatedPaginatedArr = (this.populateSelectedArr(this.formatJson(selectedArr, ''), employee, dataObj));
    }
    return (updatedPaginatedArr.length > 0) ? updatedPaginatedArr : selectedArr;
  }

  populateData(employees) {
    this.employees = this.formatJson(employees, '');
    let updatedEmpCount;
    /*Updating employees Count */
    this.employees.forEach((element) => {
      if (element !== undefined && element.data.empId === null) {
        updatedEmpCount = (this.employees.length + element.children.length) - 1;
      }
    });
    setTimeout(() => {
      this.checkParentEmpId('');
    }, 500);
    return this.employees;
  }

  receiveDataFromChild(e) {
    this.pageNo = 1;
    const sortField = '';
    this.noOfRecords = Number(e);
    this.selectedNoOfRecords = true;
    this.sharedService.clearSuccessMsgs(this.messageService, true);
    this.getEmpAccntResponse(this.paramObj(this.pageNo, this.noOfRecords, this.sortField), false);
  }

  customSort(event) {
    if (event.multiSortMeta) {
      this.paginator.changePage(0);
      if (this.sortField !== this.customSortService.customSort(event)) {
        this.sortField = this.customSortService.customSort(event);
        const noOfRecords = this.noOfRecords;
        this.isSortingChanged = (JSON.stringify(event.multiSortMeta) === JSON.stringify([this.defaultSortDataField])) ? false : true;
        if (this.sharedService.empListMultiSortMeta !== event.multiSortMeta) {
          this.sharedService.empListMultiSortMeta = event.multiSortMeta;
          if (!this.initialLoad) {
            this.clearSuccessAndErrorMessages();
          } else {
            this.initialLoad = false;
          }
          if ((this.moduleType === 'EmployeeGroup' && this.editGroupData.mode !== 'EDIT' ) || (this.moduleType === 'TwoStepRule' )) {
            this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), true);
          } else {
            this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
          }
        }
      }
    }
  }

  paginate(e) {
    if (this.pageNo !== e.page + 1) {
      this.pageNo = e.page + 1;
      this.sortField = this.param.sortField;
      const noOfRecords = this.param.pageSize;
      this.employeeSelection = '';
      this.clearSuccessAndErrorMessages();
      this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
    }
  }

  formatJson(data, type) {
    return data.map((dataEle, i) => {
      if (dataEle !== null && dataEle !== undefined) {
        const children = dataEle.accounts;
        delete dataEle.accounts;
        if (type) {
          dataEle.tempEmpID = 'T' + dataEle.empId;
          dataEle.empId = dataEle.empId + dataEle.subAcctNum;
        } else {
          if (dataEle.empId === null) {
            dataEle.empId = i + 'null';
          }
        }
        const obj: object = {
          data: dataEle
        };
        const childrenTxt = 'children';
        const expandTxt = 'expanded';
        if (children) {
          obj[childrenTxt] = this.formatJson(children, 'children');
          obj[expandTxt] = true;
        }
        return obj;
      }
    });
  }

  isParentNode(rowNode) {
    if (!rowNode.level) {
      this.currentParentNode = rowNode;
      return false;
    }
    return true;
  }

  mapResponse(empData) {
    let c = 0;
    return empData.map((data) => {
      if (data !== undefined) {
        c = c + data.children.length;
        return c;
      }
    });
  }

  getDatafromParent(data, type, load) {
    if (type) {
      this.getCompanyGrpEmpCount = data.companyGroupEmp;
      this.getCompanyGrpAccCount = data.companyGroupAcc;
    } else {
      this.chkCompanyGrp = data;
      if (data) {
        if (data.length > 0 && data[0].type === 'ORG') {
          this.validateCompanyGrp = data[0].type;
          this.selectedArr = [];
          this.checkParentEmpId('remove');

        } else {
          if (!load) {
            this.getSelectedCount(this.selectedArr);
          }
        }
      }
    }
    this.validateCompanyGrp = data.length > 0 ? data[0].type : '';
  }

  getSelectedAccCount(selectedArr) {
    this.parent = 0;
    this.child = 0;
    if (selectedArr) {
      this.countForSelectedArr(selectedArr);
    }
    this.empAccformData = selectedArr;
    const emitData = {
      empIds: this.empIds,
      subAccntNumbers: this.subAccntNumbers,
      selectedArr: (selectedArr),
    };
    this.modifiedSubAccounts.emit(this.modifiedSubAccnts);
    this.selectedAccounts.emit(emitData);
    this.employeeAccounts.emit(emitData);
    /*Initial empty empId parent checkbox Selection Functionality */
  }

  countForSelectedArr(arr) {
    const localEmpId = [];
    let partialSelected;
    arr.forEach(data => {
      if (data.parent) {
        /* Edit Scenario partialSelected will be undefined so setting it to true */
        partialSelected = (data.parent.partialSelected && data.parent.partialSelected === true) ? data.parent.partialSelected : false;
        if (partialSelected && data.parent.data.empId !== null && localEmpId.indexOf(data.parent.data.empId) === -1) {
          this.parent++;
          localEmpId.push(data.parent.data.empId);
        } else if (data.parent.data.empId.indexOf('null') !== -1) {
          this.parent++;
        }
        this.child++;
      } else {
        if (data.data.empId !== null && !partialSelected) {
          this.parent++;
        }
      }
    });
  }

  removeDuplicateEmpId(empId) {
    return this.empIds.indexOf(empId) === -1 ? this.empIds.push(empId) : this.empIds.splice(this.empIds.indexOf(empId), 1);
  }

  removeDuplicatesubAcc(subAcc) {
    if (subAcc !== undefined) {
      return this.subAccntNumbers.indexOf(subAcc) === -1 ? this.subAccntNumbers.push(subAcc) :
        this.subAccntNumbers.splice(this.subAccntNumbers.indexOf(subAcc), 1);
    }
  }

  getSelectedEmpAccounts(e, selectedVal, el) {
    this.showParentCheckboxForEmptyRuleId(el);
    this.el.nativeElement.querySelector('.pi-minus') ?
      this.el.nativeElement.querySelector('.pi-minus').classList.remove('pi-minus') :
      this.el.nativeElement.querySelector('.pi-minus');
    this.empIds = [];
    this.subAccntNumbers = [];
    let wholeArray = this.selectedArr;
    wholeArray.forEach(val => {
      if (val.children && val.data && val.data.empId.indexOf('null') === -1) {
        this.removeDuplicateEmpId(val.data.empId);
        val.children.forEach(record => {
          wholeArray = wholeArray.filter(item => item.data.empId !== record.data.empId);
        });
      }
    });

    this.modifiedSubAccnts = [];
    wholeArray.forEach(val => {
      if (val.parent && val.parent !== null) {
        const checkNullEmpId = (val.parent.data.empId && val.parent.data.empId.indexOf('null') === -1) ? val.parent.data.empId : '';
        const finalSubAccnt = {
          empId: checkNullEmpId,
          subAcctNum: val.data.subAcctNum
        };
        this.modifiedSubAccnts.push(finalSubAccnt);
        this.removeDuplicatesubAcc(val.data.subAcctNum);
      }
    });
    if ((this.moduleType === 'EmployeeGroup' && this.editGroupData.mode === 'EDIT') || this.moduleType === 'Rules') {
      this.createAssignedUnassignedIds(el, selectedVal, e);
      this.getSelectedArrForCount(e, selectedVal);
      this.emitNewlySelectedRecords();
    } else {
      this.getSelectedAccCount(this.selectedArr);
      this.selectedArr.forEach((newSelected) => {
        newSelected.selectedEmployee = true;
      });
    }
    this.selectedEmployeeAccounts.emit(this.selectedArr);
    this.rowSelected.emit();
  }

  emitNewlySelectedRecords() {
    if (this.alreadySelectedRemoval.length > 0) {
      this.selectedNewRecords = this.selectedNewRecords.filter((removed) => {
        return !this.alreadySelectedRemoval.find((employee) => {
          return employee.data.empId === removed.data.empId;
        });
      });
    }
    this.alreadySelectedRemoval.forEach((removed) => {
      removed.selectedEmployee = false;
    });
    this.selectedNewRecords.forEach((newSelected) => {
      newSelected.selectedEmployee = true;
      if (newSelected.children) {
        newSelected.children.forEach((child) => {
          child.selectedEmployee = true;
        });
      }
    });

    if (this.subAccntNewlySelected.length > 0) {
      this.subAccntNewlySelected.forEach((subAcc) => {
        const index = this.alreadySelectedRemoval.findIndex(x => x.data.empId === subAcc.data.empId);
        if (index !== -1) {
          this.alreadySelectedRemoval.splice(index, 1);
        }
        const checkParent = this.alreadySelectedRemoval.findIndex(x => x.data.empId === subAcc.parent.data.empId);
        if (checkParent !== -1) {
          this.alreadySelectedRemoval.splice(checkParent, 1);
        }
      });
    }

    let localValueIndex = [];
    let localAlreadyRemovedArr = [...this.alreadySelectedRemoval];
    localAlreadyRemovedArr.forEach((selected) => {
      if (selected.parent) {
        localValueIndex = this.unModifiedRecords.filter(item => item.data.empId === selected.parent.data.empId);
        if (localValueIndex.length > 0 && localValueIndex[0].children.length > 1 && localValueIndex[0].children.every(val => ((this.moduleType === 'EmployeeGroup' && val.data.groupIds && val.data.groupIds.indexOf(this.editGroupData.id) !== -1)
          || (this.moduleType === 'Rules' && val.data.ruleIds && val.data.ruleIds.indexOf(this.editGroupData.id) !== -1)))) {
          localAlreadyRemovedArr = localAlreadyRemovedArr.filter((removed) => {
            return !localValueIndex[0].children.find((employee) => {
              return employee.data.empId === removed.data.empId;
            });
          });
        }
      }
    });

    this.removedExistingEmpId.forEach((selected) => {
      localAlreadyRemovedArr.forEach((localRemoved, index) => {
        if ((localRemoved.parent !== null && selected.data.empId === localRemoved.parent.data.empId) || (selected.data.empId === localRemoved.data.empId)) {
          localAlreadyRemovedArr.splice(index, 1);
        }
      });
    });

    const localFormData = {
      newSelectedRecords: this.selectedNewRecords,
      removedExistingSelected: this.alreadySelectedRemoval,
      subAccntNewlySelected: this.subAccntNewlySelected,
      removedExistingEmpId: this.removedExistingEmpId,
      localAlreadyRemovedArr
    };
    this.newlySelectedEmployeeAccounts.emit(localFormData);
  }

  getSelectedArrForCount(e, selectedVal) {
    const selectedCount = [];
    if (e === 'parent') {
      const localArr = this.parentLevelSelection(selectedVal);
      localArr.forEach((emp) => {
        const index = selectedCount.findIndex(x => x.data.empId === emp.data.empId);
        if (index === -1) {
          selectedCount.push(emp);
        }
      });
    } else {
      this.childLevelSelection(selectedVal, selectedCount);
    }
    this.getSelectedAccCount(selectedCount);
  }

  createAssignedUnassignedIds(el, selectedVal, e) {
    if (el.target.nodeName === 'DIV' && el.target.className.indexOf('p-highlight') !== -1) {
      this.employeeSelection = 'selection';
      let parentSelection = [];
      if ((selectedVal.parent && selectedVal.parent.children)) {
        parentSelection = selectedVal.parent.children.filter((child) => ((this.moduleType === 'EmployeeGroup' && child.data.groupIds && child.data.groupIds.length > 0 && child.data.groupIds.indexOf(this.editGroupData.id) !== -1)
          || (this.moduleType === 'Rules' && child.data.ruleIds && child.data.ruleIds.length > 0 && child.data.ruleIds.indexOf(this.editGroupData.id) !== -1 ) ));
      }
      if (parentSelection.length === 0 || selectedVal.data.groupIds === null || selectedVal.data.ruleIds === null) {
        if (e === 'parent') {
          const localArr = this.parentLevelSelection(selectedVal);
          localArr.forEach((emp) => {
            const index = this.selectedNewRecords.findIndex(x => x.data.empId === emp.data.empId);
            if (index === -1) {
              this.selectedNewRecords.push(emp);
            }
          });
        } else {
          this.childLevelSelection(selectedVal, this.selectedNewRecords);
        }
      } else {
        this.removeNewlySelectedFrmUnselectedArr(e, selectedVal);
      }
    } else if (el.target.nodeName === 'SPAN' || el.target.className.indexOf('p-highlight') === -1) {
      this.employeeSelection = 'removal';
      if ((this.moduleType === 'EmployeeGroup' && selectedVal.data.groupIds !== null && selectedVal.data.groupIds.indexOf(this.editGroupData.id) !== -1) || (this.moduleType === 'Rules' && selectedVal.data.ruleIds !== null && selectedVal.data.ruleIds.indexOf(this.editGroupData.id) !== -1) ) {
        this.removeAlreadySelected(selectedVal, e);
      } else {
        this.removeNewlySelectedItems(e, selectedVal);
      }
    }
  }

  performUnSelection(selectedVal) {
    selectedVal.parent.children.forEach((children) => {
      const selectedExists = this.selectedArr.filter(item => item.data.empId === children.data.empId);
      /* eslint-disable-next-line max-len */
      if (selectedExists && selectedExists.length > 0 && ((this.moduleType === 'EmployeeGroup' && children.data.groupIds && children.data.groupIds.indexOf(this.editGroupData.id) !== -1) || (this.moduleType === 'Rules' && children.data.ruleIds && children.data.ruleIds.indexOf(this.editGroupData.id) !== -1))) {
        const indexes = this.alreadySelectedRemoval.findIndex(x => x.data.empId === children.data.empId);
        if (indexes !== -1) {
          this.alreadySelectedRemoval.splice(indexes, 1);
        }
      } else if (selectedExists && selectedExists.length > 0) {
        const indexes = this.selectedNewRecords.findIndex(x => x.data.empId === children.data.empId);
        if (indexes === -1) {
          this.selectedNewRecords.push(children);
        }
      }
    });
  }

  performMultipleEmployee(selectedVal, param) {
    if (selectedVal.parent && selectedVal.parent.children.length > 1 && (selectedVal.parent.children.every(val => ((this.moduleType === 'EmployeeGroup' && val.data.groupIds && val.data.groupIds.indexOf(this.editGroupData.id) !== -1 ) ||
      (this.moduleType === 'Rules' && val.data.ruleIds && val.data.ruleIds.indexOf(this.editGroupData.id) !== -1 )) ) )) {
      if (param === 'Selection') {
        this.handleSubAccntSelection(selectedVal);
      } else {
        this.handleMultipleSubAccnt(selectedVal);
      }
    } else if (selectedVal.parent && selectedVal.parent.children.length > 1) {
      if (param === 'Unselection') {
        if (selectedVal.parent.children.every(val => ((this.moduleType === 'EmployeeGroup' && val.groupIds && val.groupIds.indexOf(this.editGroupData.id) !== -1) || (this.moduleType === 'Rules' && val.ruleIds && val.ruleIds.indexOf(this.editGroupData.id) !== -1)) )) {
          this.insertSelectedChildren(selectedVal);
        } else {
          const index = this.alreadySelectedRemoval.findIndex(x => x.data.empId === selectedVal.data.empId);
          if (index === -1) {
            this.alreadySelectedRemoval.push(selectedVal);
          }
        }
        const parentIndex = this.selectedNewRecords.findIndex(x => x.data.empId === selectedVal.parent.data.empId);
        if (parentIndex !== -1) {
          this.selectedNewRecords.splice(parentIndex, 1);
        }
        if (selectedVal.parent && selectedVal.parent.children.length > 1) {
          this.performUnSelection(selectedVal);
        }
      } else {
        if ((selectedVal.parent.children.every(val => (this.selectedArr.findIndex(t => (t.data.empId === val.data.empId)) >= 0)))) {
          this.insertSelectedParent(selectedVal);
          this.insertSelectedChildren(selectedVal);
        } else {
          if ((this.moduleType === 'EmployeeGroup' && selectedVal.data.groupIds && selectedVal.data.groupIds.indexOf(this.editGroupData.id) !== -1) || (this.moduleType === 'Rules' && selectedVal.data.ruleIds && selectedVal.data.ruleIds.indexOf(this.editGroupData.id) !== -1)) {
            const indexes = this.alreadySelectedRemoval.findIndex(x => x.data.empId === selectedVal.data.empId);
            if (indexes !== -1) {
              this.alreadySelectedRemoval.splice(indexes, 1);
            }
          } else {
            const index = this.selectedNewRecords.findIndex(x => x.data.empId === selectedVal.data.empId);
            if (index === -1) {
              this.selectedNewRecords.push(selectedVal);
            }
            if ((selectedVal.parent.children.every(val => (this.selectedNewRecords.findIndex(t => (t.data.empId === val.data.empId)) >= 0)))) {
              this.insertSelectedParent(selectedVal);
            }
          }
        }
      }
    }
  }

  insertSelectedParent(selectedVal) {
    const index = this.selectedNewRecords.findIndex(x => x.data.empId === selectedVal.parent.data.empId);
    if (index === -1) {
      this.selectedNewRecords.push(selectedVal.parent);
    }
  }

  insertSelectedChildren(selectedVal) {
    selectedVal.parent.children.forEach((children) => {
      if (((this.moduleType === 'EmployeeGroup' && children.data.groupIds && children.data.groupIds.indexOf(this.editGroupData.id) !== -1) || (this.moduleType === 'Rules' && children.data.ruleIds && children.data.ruleIds.indexOf(this.editGroupData.id) !== -1))) {
        const index = this.alreadySelectedRemoval.findIndex(x => x.data.empId === children.data.empId);
        if (index === -1) {
          this.alreadySelectedRemoval.push(children);
        }
      }
    });
  }

  removeNewlySelectedFrmUnselectedArr(e, selectedVal) {
    /* eslint-disable-next-line max-len */
    const filteredValue = selectedVal.parent.children.filter((child) => (selectedVal.parent.children.length > 1 && ((this.moduleType === 'EmployeeGroup' && child.data.groupIds && child.data.groupIds.indexOf(this.editGroupData.id) !== -1) || (this.moduleType === 'Rules' && child.data.ruleIds && child.data.ruleIds.indexOf(this.editGroupData.id) !== -1))));
    if (filteredValue && filteredValue.length > 0) {
      this.performMultipleEmployee(selectedVal, 'Selection');
    } else {
      if (e === 'parent') {
        const localArr = this.parentLevelSelection(selectedVal);
        localArr.forEach((data) => {
          const index = this.alreadySelectedRemoval.findIndex(x => x.data.empId === data.data.empId);
          if (index > -1) {
            this.alreadySelectedRemoval.splice(index, 1);
          }
        });
      } else {
        this.childLevelUnSelection(selectedVal, this.alreadySelectedRemoval);
      }

      if (selectedVal.parent.children.every(val => (this.selectedArr.findIndex(t => (t.data.empId === val.data.empId)) >= 0)) &&
        selectedVal.parent.children.every(val => ((this.moduleType === 'EmployeeGroup' && val.groupIds && val.groupIds.indexOf(this.editGroupData.id) !== -1) || (this.moduleType === 'Rules' && val.ruleIds && val.ruleIds.indexOf(this.editGroupData.id) !== -1))) ) {
        selectedVal.parent.children.forEach((selected) => {
          const index = this.selectedNewRecords.findIndex(x => x.data.empId === selected.data.empId);
          if (index === -1) {
            this.selectedNewRecords.push(selected);
          }
        });
        if (this.selectedNewRecords.some(v => selectedVal.parent.children.indexOf(v) !== -1)) {
          this.selectedNewRecords.push(selectedVal.parent);
        }
      }
    }
  }

  parentLevelUnSelection(selectedVal) {
    const localArr = this.parentLevelSelection(selectedVal);
    if (selectedVal.parent.children.every(val => ((this.moduleType === 'EmployeeGroup' && val.data.groupIds && val.data.groupIds.indexOf(this.editGroupData.id) !== -1) || (this.moduleType === 'Rules' && val.data.ruleIds && val.data.ruleIds.indexOf(this.editGroupData.id) !== -1)))) {
      const empLevelIndex = this.alreadySelectedRemoval.findIndex(x => x.data.empId === selectedVal.parent.data.empId);
      if (empLevelIndex === -1) {
        this.alreadySelectedRemoval.push(selectedVal.parent);
      }
    } else {
      localArr.forEach((datas) => {
        const indexChildren = this.selectedArr.findIndex(x => x.data.empId === datas.data.empId);
        const indexes = this.alreadySelectedRemoval.findIndex(x => x.data.empId === selectedVal.data.empId);
        if (indexChildren === -1 && indexes === -1 && ((this.moduleType === 'EmployeeGroup' && datas.data.groupIds && datas.data.groupIds.indexOf(this.editGroupData.id) !== -1) || (this.moduleType === 'Rules' && datas.data.ruleIds && datas.data.ruleIds.indexOf(this.editGroupData.id) !== -1))) {
          this.alreadySelectedRemoval.push(datas);
        }
      });
    }
  }

  removeAlreadySelected(selectedVal, e) {
    /* eslint-disable-next-line max-len */
    let filteredValue = [];
    if (this.moduleType === 'Rules') {
      filteredValue = selectedVal.parent.children.filter((child) => (selectedVal.parent.children.length > 1 && child.data.groupIds && (this.moduleType === 'Rules' && child.data.ruleIds && child.data.ruleIds.indexOf(this.editGroupData.id) !== -1)));
    } else if (this.moduleType === 'EmployeeGroup') {
      filteredValue = selectedVal.parent.children.filter((child) => (selectedVal.parent.children.length > 1 && child.data.groupIds && (this.moduleType === 'EmployeeGroup' && child.data.groupIds && child.data.groupIds.indexOf(this.editGroupData.id) !== -1)));
    }
    if (filteredValue && filteredValue.length > 0) {
      this.performMultipleEmployee(selectedVal, 'Unselection');
    }
    if (e === 'parent') {
      this.parentLevelUnSelection(selectedVal);
    } else {
      const index = this.alreadySelectedRemoval.findIndex(x => x.data.empId === selectedVal.data.empId);
      if (index === -1) {
        this.alreadySelectedRemoval.push(selectedVal);
        const localArr = this.alreadySelectedRemoval;
        if ((selectedVal.parent.children.every(val => (localArr.findIndex(t => (t.data.empId === val.data.empId)) >= 0))) ||
          (selectedVal.parent.children && selectedVal.parent.children.length === 1)) {
          this.alreadySelectedRemoval.push(selectedVal.parent);
        }

        const parentIndex = this.selectedNewRecords.findIndex(x => x.data.empId === selectedVal.parent.data.empId);
        if (parentIndex > -1) {
          this.selectedNewRecords.splice(parentIndex, 1);
        }
      }
      this.alreadySelectedRemoval = this.alreadySelectedRemoval.filter((v, i, a) => a.findIndex(t => (t.data.empId === v.data.empId)) === i);
    }
  }

  handleSubAccntSelection(selectedVal) {
    const parentRecord = selectedVal.parent;
    const employeeChildren = [...parentRecord.children];
    if (employeeChildren && employeeChildren.length > 0) {
      employeeChildren.forEach((record) => {
        const index = this.selectedArr.findIndex(x => x.data.empId === record.data.empId);
        const recordExists = this.subAccntNewlySelected.findIndex(x => x.data.empId === record.data.empId);
        if (index > -1 && recordExists === -1) {
          this.subAccntNewlySelected.push(record);
        }
      });
    }
    const currentRecord = this.unModifiedRecords.filter(item => item.data.empId === selectedVal.parent.data.empId);
    if (currentRecord[0].children.every(val => (this.subAccntNewlySelected.findIndex(t => (t.data.empId === val.data.empId)) >= 0))) {
      currentRecord[0].children.forEach((data) => {
        const parentIndex = this.subAccntNewlySelected.findIndex(x => x.data.empId === data.data.empId);
        if (parentIndex > -1) {
          this.subAccntNewlySelected.splice(parentIndex, 1);
        }
      });
      const empIdIndex = this.removedExistingEmpId.findIndex(x => x.data.empId === selectedVal.parent.data.empId);
      if (empIdIndex > -1) {
        this.removedExistingEmpId.splice(empIdIndex, 1);
      }
    }

    this.removeChildrenData(selectedVal);
  }

  removeChildrenData(selectedVal) {
    if ((selectedVal.parent.children.length > 0 && selectedVal.parent.children.every(val => (this.selectedArr.findIndex(t => (t.data.empId === val.data.empId)) >= 0)))) {
      selectedVal.parent.children.forEach((selected) => {
        const alreadySelectedIndex = this.alreadySelectedRemoval.findIndex(x => x.data.empId === selected.data.empId);
        if (alreadySelectedIndex > -1) {
          this.alreadySelectedRemoval.splice(alreadySelectedIndex, 1);
        }
        const alreadyParentIndex = this.alreadySelectedRemoval.findIndex(x => x.data.empId === selectedVal.parent.data.empId);
        if (alreadyParentIndex > -1) {
          this.alreadySelectedRemoval.splice(alreadyParentIndex, 1);
        }
      });
    }
  }

  handleMultipleSubAccnt(selectedVal) {
    const parentRecord = selectedVal.parent;
    const employeeChildren = [...parentRecord.children];

    const parentIndex = employeeChildren.findIndex(x => x.data.empId === selectedVal.data.empId);
    if (parentIndex > -1) {
      employeeChildren.splice(parentIndex, 1);
    }

    const childIndex = this.subAccntNewlySelected.findIndex(x => x.data.empId === selectedVal.data.empId);
    if (childIndex > -1) {
      this.subAccntNewlySelected.splice(childIndex, 1);
    }

    if (employeeChildren && employeeChildren.length > 0) {
      employeeChildren.forEach((record) => {
        const indexes = this.selectedArr.findIndex(x => x.data.empId === record.data.empId);
        const recordExists = this.subAccntNewlySelected.findIndex(x => x.data.empId === record.data.empId);
        if (indexes > -1 && recordExists === -1) {
          this.subAccntNewlySelected.push(record);
        }
      });
    }
    const index = this.removedExistingEmpId.findIndex(x => x.data.empId === selectedVal.parent.data.empId);
    if (index === -1) {
      this.removedExistingEmpId.push(selectedVal.parent);
    }
  }

  removeNewlySelectedItems(e, selectedVal) {
    if (e === 'parent') {
      const localArr = this.parentLevelSelection(selectedVal);
      localArr.forEach((data) => {
        const index = this.selectedNewRecords.findIndex(x => x.data.empId === data.data.empId);
        if (index > -1) {
          this.selectedNewRecords.splice(index, 1);
        }
      });
    } else {
      this.childLevelUnSelection(selectedVal, this.selectedNewRecords);
      if (selectedVal.parent && selectedVal.parent.children.length > 1) {
        selectedVal.parent.children.forEach((selected) => {
          const selectedExists = this.selectedArr.filter(item => item.data.empId === selected.data.empId);
          /* eslint-disable-next-line max-len */
          if (selectedExists && selectedExists.length > 0 && ((this.moduleType === 'EmployeeGroup' && selected.data.groupIds && selected.data.groupIds.indexOf(this.editGroupData.id) !== -1) || (this.moduleType === 'Rules' && selected.data.ruleIds && selected.data.ruleIds.indexOf(this.editGroupData.id) !== -1))) {
            const alreadyParentIndex = this.alreadySelectedRemoval.findIndex(x => x.data.empId === selected.data.empId);
            if (alreadyParentIndex > -1) {
              this.alreadySelectedRemoval.splice(alreadyParentIndex, 1);
            }
          }
        });
      }
    }
  }

  childLevelSelection(selectedVal, arr) {
    const index = arr.findIndex(x => x.data.empId === selectedVal.data.empId);
    if (index === -1) {
      arr.push(selectedVal);
      if (selectedVal.parent.children.every(val => (this.selectedArr.findIndex(t => (t.data.empId === val.data.empId)) >= 0)) || (selectedVal.parent.children && selectedVal.parent.children.length === 1)) {
        arr.push(selectedVal.parent);
      }
    }
  }

  childLevelUnSelection(selectedVal, arr) {
    this.removeItem(selectedVal, arr);
    if (!(selectedVal.parent.children.every(val => (arr.findIndex(t => (t.data.empId === val.data.empId)) >= 0))) ||
      (selectedVal.parent.children && selectedVal.parent.children.length === 1)) {
      this.removeItem(selectedVal.parent, arr);
    }
  }

  removeItem(selectedVal, arr) {
    const index = arr.findIndex(x => x.data.empId === selectedVal.data.empId);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }


  parentLevelSelection(selected) {
    const parentArray = [];
    parentArray.push(selected.parent);
    selected.parent.children.forEach((children) => {
      parentArray.push(children);
    });
    return parentArray;
  }

  /* Handling the empAccounts Selection/UnSelection Functionality */
  showParentCheckboxForEmptyRuleId(el) {
    /* Removing minus */
    const wholeArray = this.el.nativeElement.querySelectorAll('.pi-minus');
    wholeArray.forEach(element => {
      element.classList.remove('pi-minus');
    });

    const parentNode = el.target.parentNode.offsetParent.parentElement;

    if (el.target.matches('.p-highlight')) {
      this.checkParentEmpId('');
    } else {
      if (!el.target.parentElement.classList.contains('p-disabled')) {
        parentNode.querySelector('.p-checkbox-icon').classList.remove('pi-check');
      }
    }
  }

  /* Handling the parent empty empId checkbox checked while loading */
  checkParentEmpId(data) {
    const wholeArray = this.el.nativeElement.querySelectorAll('tr');
    wholeArray.forEach(element => {
      if (element.querySelector('.p-highlight') && element.querySelector('.check-disabled')) {
        const entireCheckedArray = element.querySelector('.p-checkbox-icon');
        data ? entireCheckedArray.classList.remove('pi-check') : entireCheckedArray.classList.add('pi-check');
      }
    });
    this.sharedService.removeEmptyCheckbox(this.el, 'accounts');
  }

  getSelectedCount(selectedArr) {
    this.parent = 0;
    this.child = 0;
    if (selectedArr) {
      this.countForSelectedArr(selectedArr);
    }
    const emitData = {
      empIds: this.empIds,
      subAccntNumbers: this.subAccntNumbers,
      selectedArr: this.selectedArr,
    };
    this.modifiedSubAccounts.emit(this.modifiedSubAccnts);
    this.selectedAccounts.emit(emitData);
    this.employeeAccounts.emit(emitData);
  }

  onChange() {
    this.empAccountForm.get('acctNum').valueChanges.subscribe(value => {
      /* check Account Number contains 8 digits */
      this.isAccNumInvalid = this.sharedService.isAccountNumInvalid = this.accountNumberValidationService.validateAccountNumber(value);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    /* Validate the field and set the error message */
    this.empAccountForm.get('empId').valueChanges.subscribe(value => {
      this.filterBtnDisableForEmpId = this.sharedService.validationFields.filterBtnDisableForEmpId = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidEmpId = this.sharedService.validationFields.invalidEmpId = this.sharedService.isValidOrInvalid(value, 'empId', this.empAccountForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.empAccountForm.get('firstName').valueChanges.subscribe(value => {
      this.filterBtnDisableForFN = this.sharedService.validationFields.filterBtnDisableForFN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidFN = this.sharedService.validationFields.invalidFN = this.sharedService.isValidOrInvalid(value, 'firstName', this.empAccountForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.empAccountForm.get('lastName').valueChanges.subscribe(value => {
      this.filterBtnDisableForLN = this.sharedService.validationFields.filterBtnDisableForLN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidLN = this.sharedService.validationFields.invalidLN = this.sharedService.isValidOrInvalid(value, 'lastName', this.empAccountForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.empAccountForm.get('acctHolderFn').valueChanges.subscribe(value => {
      this.filterBtnDisableForacctHolderFN = this.sharedService.validationFields.filterBtnDisableForacctHolderFN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidacctHolderFN = this.sharedService.validationFields.invalidacctHolderFN = this.sharedService.isValidOrInvalid(value, 'acctHolderFn', this.empAccountForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.empAccountForm.get('acctHolderLn').valueChanges.subscribe(value => {
      this.filterBtnDisableForacctHolderLN = this.sharedService.validationFields.filterBtnDisableForacctHolderLN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidacctHolderLN = this.sharedService.validationFields.invalidacctHolderLN = this.sharedService.isValidOrInvalid(value, 'acctHolderLn', this.empAccountForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });
  }

  toggleFunction() {
    this.toggle = !this.toggle;
  }

  clickedClearButton(resetSortFromUI?) {
    this.resetForm(resetSortFromUI);
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    const noOfRecords = this.noOfRecords;
    this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
  }

  resetForm(resetSortFromUI?) {
    if (resetSortFromUI) {
      this.multiSortMeta = [this.defaultSortDataField];
    }
    this.empAccountForm.reset();
    this.paginator.changePage(0);
    this.errMsg = [];
    this.filterClicked = false;
    this.isAccNumInvalid = this.sharedService.isAccountNumInvalid = false;
    this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
  }

  applyFilter() {
    this.paginator.changePage(0);
    this.filterClicked = true;
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    const noOfRecords = this.noOfRecords;
    this.clearSuccessAndErrorMessages();
    this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
  }

  // Clear success & Error msg only. leave the warning msgs
  clearSuccessAndErrorMessages() {
    this.sharedService.clearSuccessMsgs(this.messageService, true);
    this.sharedService.clearErrorMessage();
  }
}
