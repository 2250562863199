import {Component, Inject, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Observer, Observable} from 'rxjs';
import {FormDataService, SharedConstant, SharedService, ManageEmpGrpService, SharedMasterGroupsComponent} from '@ems/shared';
import {emtrConstant} from '../../../constants/emtrConstants';
import {RuleService} from '../../../services/rule-service/rule.service';
import {SharedManageEmployeeAccountsComponent, SharedManageEmployeeGroupComponent} from '@ems/shared';
import {EmtrPagination} from '../../../models/pagination-model';
import {EmtrService} from '../../../emtr.service';
import { ClientSelectionTableComponent } from '../../client-selection-table/client-selection-table.component';
import { MessageService } from 'primeng/api';
import {Subscription} from 'rxjs';


@Component({
  selector: 'tr-rules-step3-assign-employees',
  templateUrl: './rules-step3.component.html'
})

export class RulesStep3Component implements OnInit, OnDestroy {
  employeeGroups: any[] = [];
  unassignedEmpGroup: any;
  employeeAccounts: any[] = [];
  assignFormData: any;
  message: any;
  isClicked = true;
  isRowSelected = false;
  emtrConst = emtrConstant;
  localObserver: any;
  empIds: any[] = [];
  subAccntNumbers: any[] = [];
  ruleType: string;
  getInitialData: any;
  initialLoad = false;
  tableCountData: any = {
    empCount: 0,
    subAccount: 0,
    empSelected: 0,
    subAccSelected: 0,
    totalRecords: 0,
    paginationCount: 0
  };
  setPreviousStep = false;
  pageObject: EmtrPagination = {} as EmtrPagination;
  employees: any[] = [];
  masterGroups: any[] = [];
  isRuleExpired = false;
  loadedModuleType = 'Rules';
  modifiedSubAccnts: any[] = [];
  selectedModuleType = 'rules-Step3';
  newlyAssignedEmployeeAccounts: any = {};
  modifiedSelectedRecords: any = {};
  sharedConstant = SharedConstant;
  businessUnit: string;
  changeInObj: any;
  unassignOrgIds: any[] = [];
  assignOrgIds: any[] = [];
  unAssignMstrAccts: any[] = [];
  assignBU: any[] = [];
  unAssignBU: any[] = [];
  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild(SharedManageEmployeeAccountsComponent) sharedEmpAcc: SharedManageEmployeeAccountsComponent;
  @ViewChild(SharedManageEmployeeGroupComponent) sharedEmpGrp: SharedManageEmployeeGroupComponent;
  @ViewChild(SharedMasterGroupsComponent) sharedMasterGrp: SharedMasterGroupsComponent;
  @ViewChild(ClientSelectionTableComponent) clientSelection: ClientSelectionTableComponent;

  confirmMessage: any;
  showActionBtn = false;
  selectedMasterAcc = false;
  selectedMasterGrp: any;
  masterAccChange: any;
  buSelected = false;
  shouldDisabledEmployeesOrParticipants = false;
  shouldDisabledGroupsOrPlans = false;
  shouldDisabledMasterGroups = false;
  shouldDisabledMasterAccounts = false;
  selectionWarningMesgForMGP: string;
  activeIndex = 0;
  selectionWarningMessage: string;
  showSelectionMessage = false;

  constructor(public formDataService: FormDataService, private router: Router, private r: ActivatedRoute,
              public ruleService: RuleService, public sharedService: SharedService, @Inject('entitlementConst') public entitlementConst, public emtrService: EmtrService,
              public manageEmpGrpService: ManageEmpGrpService, public messageService: MessageService) {
    this.assignFormData = {...this.formDataService.getFormData()};
    if (this.assignFormData.globalRule) {
      this.assignFormData.employeeAccounts = [];
      this.assignFormData.empIds = [];
    } else {
      this.assignFormData.assignOrgIds = [];
    }
    if (this.assignFormData.globalRule && (this.assignFormData.status === 'ACTIVE' || this.assignFormData.status === 'SCHEDULED')) {
      if ((this.assignFormData.global && this.assignFormData.global.type === 'GLOBAL')) {
        this.assignFormData.employeeGroups = {name: 'Business Unit', selectedAcct: true, type: 'ORG'};
      } else if (this.assignFormData.employeeGroups && this.employeeGroups.length === 1) {
        if (this.assignFormData.employeeGroups[0].type === 'ORG') {
          this.assignFormData.empAccounts = [];
        }
      }
    }
    this.sharedService.groupType = this.assignFormData.globalRule ? this.sharedConstant.manageMasterGroups.groupTypeMGP : '';
    this.employeeAccounts = this.employeeAccounts.concat(this.assignFormData.employeeAccounts);
    this.employeeGroups = this.employeeGroups.concat(this.assignFormData.employeeGroups);
    this.empIds = this.assignFormData.empIds;
    this.subAccntNumbers = this.assignFormData.subAccntNumbers;
    this.assignOrgIds = this.assignFormData.assignOrgIds;
    this.assignFormData.rulesCategory = (this.assignFormData.override === 'Y') ? 'Override' : this.assignFormData.rulesCategory;
    this.pageObject.pageNo = 1;
    this.pageObject.pageSize = 15;
  }

  ngOnInit() {
   this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo && clientInfo !== null) {
      this.businessUnit = clientInfo.businessUnit;
      this.selectionWarningMessage = this.sharedConstant[this.businessUnit].selectionWarningMessage;
      this.selectionWarningMesgForMGP = this.sharedConstant.MGP.selectionWarningMesgForMGP;
      }
    });
    if (this.assignFormData.rulesCategory === 'RESTRICT_LST_ENG') {
      this.ruleType = this.emtrConst.rulesConstant.ruleGlobalConst.restriction;
    } else if (this.assignFormData.rulesCategory === 'WATCH_LST_ENG') {
      this.ruleType = this.emtrConst.rulesConstant.ruleGlobalConst.watchList;
    } else {
      this.ruleType = this.emtrConst.rulesConstant.ruleGlobalConst.override;
    }

    if (this.assignFormData.expiredRuleStatus && this.assignFormData.expiredRuleStatus === this.emtrConst.rulesConstant.expiredCapsTxt) {
      this.isRuleExpired = true;
      this.message = '<strong>' + this.assignFormData.name + ' - ' + this.ruleType + '</strong> ' + this.emtrConst.rulesConstant.ruleMessages.ruleWasExpired;
      this.ruleService.toastType.emit('info');
    } else {
      this.isRuleExpired = false;
      if (this.assignFormData.expiredRuleStatus && this.assignFormData.expiredRuleStatus !== this.emtrConst.rulesConstant.ruleDraftStatus) {
        this.sharedService.showRulesDraftSaveSuccessMsg = true;
        this.message = '<strong>' + this.assignFormData.name + ' - ' + this.ruleType + '</strong> ' + this.emtrConst.rulesConstant.ruleMessages.ruleWasSuccessfullySaved;
      } else if (this.assignFormData.globalRule) {
        this.sharedService.showRulesDraftSaveSuccessMsg = true;
        this.message = '<strong>' + this.assignFormData.name + ' - ' + this.ruleType + '</strong> ' + this.emtrConst.rulesConstant.ruleMessages.ruleWaSuccessfullySavedAsDraft + this.emtrConst.rulesConstant.ruleMessages.continueByAssigningRuleToMasterAcc;
      } else {
        this.sharedService.showRulesDraftSaveSuccessMsg = true;
        this.message = '<strong>' + this.assignFormData.name + ' - ' + this.ruleType + '</strong> ' + this.emtrConst.rulesConstant.ruleMessages.ruleWaSuccessfullySavedAsDraft + this.sharedConstant[this.businessUnit].continueByAssigningRule;
      }
      this.ruleService.toastType.emit('success');
    }
    if (this.assignFormData.showToast && this.message) {
      this.ruleService.toastMsg.emit(this.message);
    }

    this.sharedService.schErrorMsg.subscribe(value => {
      this.ruleService.schErrorMsg.emit(value);
      if (value.length > 0) {
        this.ruleService.showSchError.emit(true);
      } else {
        this.ruleService.showSchError.emit(false);
      }
    });

    this.confirmMessage = this.confirmationMessage.confirmMessage;
    this.changeInObj = this.formInputHeaders();
  }

  canDeactivate(event, observer) {
    this.confirmMessage.message = this.confirmMessage.message ? this.confirmMessage.message : this.emtrConst.rulesConstant.confirmationMessage.menuClickMsg;
    if (this.isClicked && this.isRowSelected && (this.employeeGroups.length > 0 || this.employeeAccounts.length > 0 || this.assignOrgIds.length > 0 || this.unassignOrgIds.length > 0)) {
      this.localObserver = observer;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }

  getInitialCount(e) {
    this.getInitialData = e;
    this.sharedEmpAcc.getDatafromParent(this.getInitialData, 'count', '');
  }

  /* get the emitted assigned & unassigned ordIds from the child component */
  getSelectedClientData(e) {
    this.assignOrgIds = e.selectedOrgIDArr;
    this.unassignOrgIds = e.unAssignOrgIdsArray;
    this.subAccntNumbers = [];
    this.employeeAccounts = e.selectedArr;
    this.subAccntNumbers = e.subAccntNumbers;
    const index = this.employeeGroups.map(x => x.type).indexOf('ORG');
    if (index > -1 && this.employeeAccounts && this.employeeAccounts.length > 0) {
      this.unassignedEmpGroup = this.employeeGroups[index].id ? [this.employeeGroups[index].id] : [];
      this.employeeGroups = [];
    }
    setTimeout(() => {
      this.sharedMasterGrp.getDatafromParent(this.employeeAccounts);
    }, 10);
  }

  getDataEmpGrp(e) {
    this.employeeGroups = e.selectedArr;
    this.shouldDisabledEmployeesOrParticipants = this.employeeGroups && (this.employeeGroups.length !== 0);
    if (this.shouldDisabledEmployeesOrParticipants) {
      this.activeIndex = 0;
      if (!this.showSelectionMessage) {
        this.ruleService.toastType.emit('warn');
        this.ruleService.toastMsg.emit(this.selectionWarningMessage);
        this.showSelectionMessage = true;
        this.sharedService.showRulesDraftSaveSuccessMsg = false;
      }
    } else if (this.showSelectionMessage && !this.shouldDisabledGroupsOrPlans && !this.shouldDisabledEmployeesOrParticipants) {
      this.ruleService.toastMsg.emit('');
      this.showSelectionMessage = false;
    }
    this.unassignedEmpGroup = e.unassignedEmpGroup;
    const index = this.employeeGroups.map(x => x.type).indexOf('ORG');
    if (index > -1) {
      this.employeeAccounts = [];
    }
    this.sharedEmpAcc.getDatafromParent(e.selectedArr, '', '');
  }

  getDataEmpAcc(e) {
    const empId = [];
    e.empIds.forEach((val) => {
      if (val.indexOf('null') === -1 && val !== null) {
        empId.push(val);
      }
    });
    this.subAccntNumbers = [];

    this.employeeAccounts = e.selectedArr;
    this.empIds = empId;
    this.subAccntNumbers = e.subAccntNumbers;
    const index = this.employeeGroups.map(x => x.type).indexOf('ORG');
    if (index > -1 && this.employeeAccounts && this.employeeAccounts.length > 0) {
      this.unassignedEmpGroup = [this.employeeGroups[index].id];
      this.employeeGroups = [];
    }
    this.sharedEmpGrp.getDatafromParent(this.employeeAccounts);
    this.initialLoad = true;
  }

  goToNextStep() {
    this.isClicked = false;
    this.storePageInfo();
    this.getResponse();
  }

  storePageInfo() {
    if (this.assignFormData && this.assignFormData.oldRuleIdForCopy !== '' && this.assignFormData.mode === 'Copy') {
      this.removeEmpIdFromSubAccnt();
    }
    let formObj = {};
    if (this.assignFormData.globalRule) {
      this.sharedService.groupType = this.sharedConstant.manageMasterGroups.groupTypeMGP;
    } else {
      this.sharedService.groupType = '';
    }
    const acctVal =  this.assignFormData.globalRule ? this.assignOrgIds : this.employeeAccounts;
    const localSelectedItem = this.sharedService.setSelectionItem(acctVal);
    const unAssignedMsterAcct = this.sharedService.setSelectionItem(this.unassignOrgIds);
    formObj = {
      empIds: this.setPreviousStep ? [] : localSelectedItem.empIds,
      subAccntNumbers: this.setPreviousStep ? [] : localSelectedItem.subAccntNumbers,
      employeeAccounts: this.employeeAccounts,
      newSelectedRecords: this.newlyAssignedEmployeeAccounts.newSelectedRecords,
      removedExistingSelected: this.newlyAssignedEmployeeAccounts.removedExistingSelected,
      assignEmpIds: this.modifiedSelectedRecords.assignEmpIds,
      assignSubAcctNumbers: this.assignFormData.globalRule  ? localSelectedItem.subAccntNumbers : this.modifiedSelectedRecords.assignSubAcctNumbers,
      unAssignEmpIds: this.modifiedSelectedRecords.unAssignEmpIds,
      unAssignSubAcctNumbers: this.assignFormData.globalRule  ? unAssignedMsterAcct.subAccntNumbers : this.modifiedSelectedRecords.unAssignSubAcctNumbers,
      subAccntNewlySelected: this.newlyAssignedEmployeeAccounts.subAccntNewlySelected,
      removedExistingEmpId: this.newlyAssignedEmployeeAccounts.removedExistingEmpId,
      employeeGroups: this.employeeGroups,
      unassignOrgIds: this.unassignOrgIds,
      assignOrgIds: localSelectedItem.subAccntNumbers,
      assignBU: (this.employeeGroups.length > 0 && this.employeeGroups[0].name === this.emtrConst.ruleSteps.step3.allClientInfo.clientName) ? ['RBS'] : [],
      unAssignBU: this.unAssignBU ? this.unAssignBU : [],
    };
    this.modifiedSubAccnts = (this.modifiedSubAccnts === undefined) ? [] : this.modifiedSubAccnts;
    this.modifiedSubAccnts = (this.modifiedSubAccnts && this.modifiedSubAccnts.length === 0) ? this.emtrService.setModifiedRecords(this.modifiedSubAccnts, this.subAccntNumbers, this.employeeAccounts) : this.modifiedSubAccnts;
    this.formDataService.setStep3(formObj, true);
  }

  removeEmpIdFromSubAccnt() {
    if (this.modifiedSelectedRecords.unAssignEmpIds && this.modifiedSelectedRecords.unAssignEmpIds.length > 0 && this.modifiedSelectedRecords.assignSubAcctNumbers.length > 0) {
      this.modifiedSelectedRecords.unAssignEmpIds.forEach((empId) => {
        const index = this.modifiedSelectedRecords.assignSubAcctNumbers.findIndex(x => x.empId === empId);
        if (index > -1) {
          this.modifiedSelectedRecords.assignSubAcctNumbers.splice(index, 1);
        }
      });
    }
  }

  updateSelectedArray(e) {
    this.employeeAccounts = e;
    this.shouldDisabledGroupsOrPlans = this.employeeAccounts && (this.employeeAccounts.length !== 0);
    if (this.shouldDisabledGroupsOrPlans) {
      this.activeIndex = 1;
      if (!this.showSelectionMessage) {
        this.ruleService.toastType.emit('warn');
        this.ruleService.toastMsg.emit(this.selectionWarningMessage);
        this.showSelectionMessage = true;
        this.sharedService.showRulesDraftSaveSuccessMsg = false;
      }
    } else if (this.showSelectionMessage && !this.shouldDisabledGroupsOrPlans && !this.shouldDisabledEmployeesOrParticipants) {
      this.ruleService.toastMsg.emit('');
      this.showSelectionMessage = false;
    }
  }

  updateNewlySelectedArray(e) {
    this.newlyAssignedEmployeeAccounts = e;
    const assignedData = this.sharedService.setSelectionItem(e.newSelectedRecords);
    let finalLocalUnselected;
    let multipEmployeeSelection;
    let empIdAlreadyRemoved;
    if (e.localAlreadyRemovedArr.length > 0) {
      const localFinalLocalUnselected = this.sharedService.setSelectionItem(e.localAlreadyRemovedArr);
      finalLocalUnselected = localFinalLocalUnselected ? localFinalLocalUnselected : [];
    }

    if (e.subAccntNewlySelected.length > 0) {
      const localMultipEmployeeSelection = this.sharedService.setSelectionItem(e.subAccntNewlySelected);
      multipEmployeeSelection = localMultipEmployeeSelection ? localMultipEmployeeSelection : [];
    }

    if (e.removedExistingEmpId.length > 0) {
      const localEmpIdAlreadyRemoved = this.sharedService.setSelectionItem(e.removedExistingEmpId);
      empIdAlreadyRemoved
        = localEmpIdAlreadyRemoved ? localEmpIdAlreadyRemoved : [];
    }

    this.modifiedSelectedRecords = {
      assignEmpIds: this.sharedService.removeDuplicates(this.sharedService.formData(assignedData, multipEmployeeSelection, 'empIds')),
      assignSubAcctNumbers: this.sharedService.removeDuplicates(this.sharedService.formData(assignedData, multipEmployeeSelection, 'subAccntNumbers')),
      unAssignEmpIds: this.sharedService.removeDuplicates(this.sharedService.formData(finalLocalUnselected, empIdAlreadyRemoved, 'empIds')),
      unAssignSubAcctNumbers: (finalLocalUnselected !== undefined) ? this.sharedService.removeDuplicates(finalLocalUnselected.subAccntNumbers) : [],
    };
    this.storePageInfo();
  }

  getSubAccnts(e) {
    this.modifiedSubAccnts = e;
  }

  formInputHeaders() {
    let outputVal;
    const localFormObj = this.formDataService.getFormData();
    delete localFormObj.newSelectedRecords;
    delete localFormObj.removedExistingSelected;
    delete localFormObj.removedExistingEmpId;
    delete localFormObj.subAccntNewlySelected;
    /* Formed Final DELTA object */
    outputVal = {
      employeeGroups: this.employeeGroups ? this.employeeGroups : [],
      unassignedEmpGroup: this.unassignedEmpGroup ? this.unassignedEmpGroup : [],
      id: localFormObj.id,
      orgId: localFormObj.orgId,
      assignEmpIds: localFormObj.assignEmpIds ? localFormObj.assignEmpIds : [],
      assignSubAcctNumbers: localFormObj.assignSubAcctNumbers ? localFormObj.assignSubAcctNumbers : [],
      unAssignEmpIds: (this.assignFormData && this.assignFormData.oldRuleIdForCopy !== '' && this.assignFormData.mode === 'Copy') ? [] : localFormObj.unAssignEmpIds ? localFormObj.unAssignEmpIds : [],
      unAssignSubAcctNumbers: (this.assignFormData && this.assignFormData.oldRuleIdForCopy !== '' && this.assignFormData.mode === 'Copy') ? [] : localFormObj.unAssignSubAcctNumbers ? localFormObj.unAssignSubAcctNumbers : [],
      assignBU: this.assignBU ? this.assignBU : [],
      unAssignBU: this.unAssignBU ? this.unAssignBU : [],
    };
    return outputVal;
  }


  getResponse() {
    const outputVal = this.formInputHeaders();
    const unAssignedSubAccntNumbers = [];
    const index = this.employeeGroups.map(x => x.type).indexOf('ORG');
    if (index > -1) {
      if (this.assignFormData.globalRule) {
        /* When BU is selected send all the previously assigned master accounts as unAssigned in payload  */
        this.unAssignMstrAccts.forEach(accntNo => {
          unAssignedSubAccntNumbers.push({empdId: '', subAcctNum: accntNo});
        });
        outputVal.unAssignSubAcctNumbers = unAssignedSubAccntNumbers.length > 0 ? unAssignedSubAccntNumbers : outputVal.unAssignSubAcctNumbers;
        outputVal.assignBU = (this.assignFormData.globalRule && this.employeeGroups[0].name === this.emtrConst.ruleSteps.step3.allClientInfo.clientName) ? ['RBS'] : [];
        outputVal.employeeGroups = [];
      } else {
        outputVal.unAssignEmpIds = this.newlyAssignedEmployeeAccounts.localAlreadyRemovedArr.length > 0 ? this.modifiedSelectedRecords.unAssignEmpIds : this.empIds;
        this.subAccntNumbers.forEach(accntNo => {
          unAssignedSubAccntNumbers.push({empdId: '', subAcctNum: accntNo});
        });
        outputVal.unAssignSubAcctNumbers = this.newlyAssignedEmployeeAccounts.localAlreadyRemovedArr.length > 0 ? this.modifiedSelectedRecords.unAssignSubAcctNumbers : unAssignedSubAccntNumbers;
      }
    }
    if (this.assignFormData.globalRule && this.assignFormData.assignBU && (outputVal.employeeGroups.length > 0 || outputVal.assignSubAcctNumbers.length > 0)) {
      outputVal.unAssignBU = this.assignFormData.assignBU;
      outputVal.assignBU = [];
    }
    if (this.assignFormData.globalRule) {
      delete outputVal.orgId;
      delete this.changeInObj.orgId;
    }
    if (!this.assignFormData.globalRule) {
      delete outputVal.assignBU;
      delete outputVal.unAssignBU;
    }
    const differenceInFormObj = this.compareFormObj(outputVal);
    if (differenceInFormObj || this.assignFormData.mode === this.emtrConst.rulesConstant.rulesLanding.copy) {
      this.ruleService.assignEmployees(outputVal).subscribe(res => {
        if (res.status === 200) {
          this.formDataService.setIdForCopy('');
          this.sharedService.clearValidationFields();
          this.router.navigate(['../step4'], {relativeTo: this.r});
        }
      });
    } else {
      this.formDataService.setIdForCopy('');
      this.sharedService.clearValidationFields();
      this.router.navigate(['../step4'], {relativeTo: this.r});
    }
  }

  compareFormObj(outputVal) {
    if (outputVal.employeeGroups && outputVal.employeeGroups.length > 0) {
      outputVal.employeeGroups.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
    }
    if (this.changeInObj.employeeGroups && this.changeInObj.employeeGroups.length > 0) {
      if (this.changeInObj.employeeGroups.length === 1 && this.changeInObj.employeeGroups[0].name === 'Business Unit') {
        this.changeInObj.employeeGroups = [];
      } else {
        this.changeInObj.employeeGroups.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
      }
    }
    if (outputVal.assignOrgIds && outputVal.assignOrgIds.length > 0) {
      outputVal.assignOrgIds.sort();
    }
    if (this.changeInObj.assignOrgIds && this.changeInObj.assignOrgIds.length > 0) {
      this.changeInObj.assignOrgIds.sort();
    }
    this.changeInObj.assignEmpIds = [];
    this.changeInObj.assignSubAcctNumbers = this.assignFormData.globalRule ? this.changeInObj.assignSubAcctNumbers : [];
    this.changeInObj.unAssignEmpIds = [];
    this.changeInObj.unAssignSubAcctNumbers = [];

    return (JSON.stringify(this.changeInObj) !== JSON.stringify(outputVal));
  }

  /* Row is selected in Employee Group/Employee/Clients setting flag*/
  employeeRowSelected(data) {
    this.isRowSelected = data;
    this.storePageInfo();
    this.formDataService.setEmpGrpModified(true);
  }

  /* Handling the Save from confirm-message clicking 'Yes' */
  goToPreviousStep() {
    const reviewDataCopy = {...this.formDataService.getFormData()};
    const formObj = {
      employeeAccounts: reviewDataCopy.employeeAccounts,
      employeeGroups: reviewDataCopy.employeeGroups,
      empIds: reviewDataCopy.empIds.length > 0 ? reviewDataCopy.empIds : [],
      subAccntNumbers: reviewDataCopy.subAccntNumbers.length > 0 ? reviewDataCopy.subAccntNumbers : [],
      unassignOrgIds: this.unassignOrgIds ? this.unassignOrgIds : [],
      assignOrgIds: this.assignOrgIds ? this.assignOrgIds : [],
      assignBU: (this.employeeGroups.length > 0 && this.employeeGroups[0].name === this.emtrConst.ruleSteps.step3.allClientInfo.clientName) ? ['RBS'] : [],
      unAssignBU: this.unAssignBU ? this.unAssignBU : [],
    };
    const outputVal = this.formInputHeaders();
    if (this.isRowSelected) {
      this.confirmMessage.message = this.emtrConst.rulesConstant.confirmationMessage.previousBtnClickMsg;
      Observable.create((observer: Observer<boolean>) => {
        this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
      }).subscribe(accept => {
        if (accept) {
          this.isClicked = false;
          const unAssignedSubAccntNumbers = [];
          const index = this.employeeGroups.map(x => x.type).indexOf('ORG');
          if (index > -1) {
            if (this.assignFormData.globalRule) {
              /* When BU is selected send all the previously assigned master accounts as unAssigned in payload  */
              this.unAssignMstrAccts.forEach(accntNo => {
                unAssignedSubAccntNumbers.push({empdId: '', subAcctNum: accntNo});
              });
              outputVal.unAssignSubAcctNumbers = unAssignedSubAccntNumbers.length > 0 ? unAssignedSubAccntNumbers : outputVal.unAssignSubAcctNumbers;
              outputVal.assignBU = (this.assignFormData.globalRule && this.employeeGroups[0].name === this.emtrConst.ruleSteps.step3.allClientInfo.clientName) ? ['RBS'] : [];
              outputVal.employeeGroups = [];
            } else {
              outputVal.unAssignEmpIds = this.newlyAssignedEmployeeAccounts.localAlreadyRemovedArr.length > 0 ? this.modifiedSelectedRecords.unAssignEmpIds : this.empIds;
              this.subAccntNumbers.forEach(accntNo => {
                unAssignedSubAccntNumbers.push({empdId: '', subAcctNum: accntNo});
              });
              outputVal.unAssignSubAcctNumbers = this.newlyAssignedEmployeeAccounts.localAlreadyRemovedArr.length > 0 ? this.modifiedSelectedRecords.unAssignSubAcctNumbers : unAssignedSubAccntNumbers;
            }
          }
          if (this.assignFormData.globalRule && this.assignFormData.assignBU && (outputVal.employeeGroups.length > 0 || outputVal.assignSubAcctNumbers.length > 0)) {
            outputVal.unAssignBU = this.assignFormData.assignBU;
            outputVal.assignBU = [];
          }
          if (this.assignFormData.globalRule) {
            delete outputVal.orgId;
            delete this.changeInObj.orgId;
          }
          if (!this.assignFormData.globalRule) {
            delete outputVal.assignBU;
            delete outputVal.unAssignBU;
          }
          const differenceInFormObj = this.compareFormObj(outputVal);
          if (differenceInFormObj) {
            this.ruleService.assignEmployees(outputVal).subscribe(data => {
              if (data.status === 200) {
                this.formDataService.setIdForCopy('');
                this.formDataService.setStep3(formObj, false);
                this.navigateTo('../step2');
              }
            });
          } else {
            this.formDataService.setIdForCopy('');
            this.formDataService.setStep3(formObj, false);
            this.navigateTo('../step2');
          }
          /*this.error.getMessage().subscribe((data) => {
              if (data.text !== undefined) {
                  this.localObserver.next(false);
                  this.localObserver.complete();
              }
          });*/
        }
      });
    } else {
      this.formDataService.setStep3(formObj, false);
      this.navigateTo('../step2');
    }
  }

  navigateTo(path) {
    this.router.navigate([path], {relativeTo: this.r});
  }

  cancelCreateRule() {
    this.confirmMessage.message = this.emtrConst.rulesConstant.confirmationMessage.cancelBtnClickMsg;
    this.router.navigate(['/ems/emtr/rules/rules-list']);
  }

  getEmpGrpRecords(param) {
    this.manageEmpGrpService.getEmployeeGrpOrPlanList(param.paramObj, param.filterObj).subscribe((response) => {
      if (this.assignFormData.globalRule) {
        if (response.data.number === 0 && (param.filterObj.groupName === '' || this.emtrConst.ruleSteps.step3.allClientInfo.k4ClientName.toUpperCase().includes(param.filterObj.groupName.toUpperCase())) && (param.filterObj.groupDescription === '')
         && (param.filterObj.lastUpdatedFromDate === '')) {
          response.data.content.unshift({ name: 'Business Unit', type: 'ORG' });
        }
        this.showActionBtn = true;
      }
      this.employees = this.masterGroups = response.data.content;
      if (this.employees.length > 0) {
        if (this.tableCountData.empCount === 0) {
          this.tableCountData.empCount = this.employees[0].noOfEmployees;
          this.tableCountData.subAccCount = this.employees[0].noOfSubAccounts;
          const companyGroupEmpData = this.tableCountData.empCount;
          const companyGroupAccData = this.tableCountData.subAccCount;
          const emitData = {companyGroupEmp: companyGroupEmpData, companyGroupAcc: companyGroupAccData};
          if (emitData.companyGroupAcc !== undefined && emitData.companyGroupEmp !== undefined) {
            this.getInitialCount(emitData);
          }
          if (this.assignFormData.employeeGroups) {
            const empGrps = Object.keys(this.assignFormData.employeeGroups);
            empGrps.map((key) => {
              const value = this.assignFormData.employeeGroups[key];
              this.tableCountData.empSelected += value.noOfEmployees;
              this.tableCountData.subAccSelected += value.noOfSubAccounts;
            });
            const emitDataVal = {
              selectedArr: this.assignFormData.employeeGroups,
              empSelected: this.tableCountData.empSelected,
              subAccSelected: this.tableCountData.subAccSelected,
              empCount: this.tableCountData.empCount,
              subAccCount: this.tableCountData.subAccCount
            };
            if (emitDataVal.selectedArr.length > 0) {
              if (this.showActionBtn) {
                this.updatedMasterGrpData(emitDataVal);
              } else {
                this.getDataEmpGrp(emitDataVal);
              }
            }
          }
        }
        this.tableCountData.totalRecords = response.data.totalElements;
        this.tableCountData.paginationCount = response.data.totalElements;
        return this.employees;
      } else {
        this.masterGroups = [];
        this.employees = [];
        this.tableCountData = {};
        return this.employees;
      }
    });
  }

  paginateEmployeeList($event) {
    this.getEmpGrpRecords($event);
  }

  ngOnDestroy(): void {
    this.sharedService.groupType = '';
    this.ruleService.toastMsg.emit('');
    this.ruleService.toastType.emit('');
    this.sharedService.clearValidationFields();
  }

  updatedMasterGrpData(data) {
    this.masterGrpData(data.selectedArr);
    this.unassignedEmpGroup = data.unassignedEmpGroup && data.unassignedEmpGroup[0] !== undefined ? data.unassignedEmpGroup : [];
    const index = this.employeeGroups.map(x => x.type).indexOf('ORG');
    if (index > -1) {
      this.buSelected = true;
      this.assignOrgIds = [];
      this.employeeAccounts = [];
      this.assignFormData.empAccounts = [];
    }
    if (this.clientSelection) {
      this.clientSelection.getDatafromParent(data.selectedArr, '', '');
    }
    const prevMasterAcc: any = (this.selectedMasterGrp) ? [...this.selectedMasterGrp] : null;
    if (prevMasterAcc !== null) {
      if ( prevMasterAcc.length === this.employeeGroups.length ) {
      for (const key in this.employeeGroups) {
        if (this.employeeGroups[key].id === prevMasterAcc[key].id) {
          this.selectedMasterAcc = false;
        } else {
          this.selectedMasterAcc = true;
          this.buSelected = false;
        }
      }
    }
    } else {
      this.selectedMasterAcc = true;
    }

    if (this.buSelected) {
      this.selectedMasterAcc = true;
      this.buSelected = false;
    }

  }

  updatedMasterAcc(event) {
    this.employeeAccounts = event;
    this.shouldDisabledMasterGroups = this.employeeAccounts && (this.employeeAccounts.length !== 0);
    if (this.shouldDisabledMasterGroups) {
      this.activeIndex = 1;
      if (!this.showSelectionMessage) {
        this.ruleService.toastType.emit('warn');
        this.ruleService.toastMsg.emit(this.selectionWarningMesgForMGP);
        this.showSelectionMessage = true;
        this.sharedService.showRulesDraftSaveSuccessMsg = false;
      }
    } else if (this.showSelectionMessage && !this.shouldDisabledMasterGroups && !this.shouldDisabledMasterAccounts) {
      this.ruleService.toastMsg.emit('');
      this.showSelectionMessage = false;
    }
    event.forEach(val => {
      (val.firstName && val.selectedAcct) ? this.selectedMasterAcc = true : this.selectedMasterAcc = false;
    });
    this.masterAccChange = event.length;
  }

  masterGrpData(event) {
    this.selectedMasterGrp = event;
    this.employeeGroups = event;
    this.shouldDisabledMasterAccounts = this.employeeGroups && (this.employeeGroups.length !== 0);
    if (this.shouldDisabledMasterAccounts) {
      this.activeIndex = 0;
      if (!this.showSelectionMessage) {
        this.ruleService.toastType.emit('warn');
        this.ruleService.toastMsg.emit(this.selectionWarningMesgForMGP);
        this.showSelectionMessage = true;
        this.sharedService.showRulesDraftSaveSuccessMsg = false;
      }
    } else if (this.showSelectionMessage && !this.shouldDisabledMasterGroups && !this.shouldDisabledMasterAccounts) {
      this.ruleService.toastMsg.emit('');
      this.showSelectionMessage = false;
    }
  }

  onChangeTab(event) {
    this.messageService.clear();
    this.sharedService.resetFilterForm.emit({ tabChange: true, tabName: event.originalEvent.target.innerText });
  }
}
